import React, { useEffect } from "react"
import styles from "./advertisement.module.css"

const Advertisement = () => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
      }
    }, 200)
  }, [])

  return (
    <div className={styles.container}>
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-4939718182327406"
        data-ad-slot="8184443017"
        data-ad-format="auto"
      ></ins>
    </div>
  )
}

export default Advertisement
