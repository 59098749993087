import React from "react"
import { AiOutlineFontSize } from "react-icons/ai"
import { FiToggleLeft, FiToggleRight } from "react-icons/fi"
import { FaFont, FaRedditAlien } from "react-icons/fa"
import {
  MdSlowMotionVideo,
  MdPhotoLibrary,
  MdFileDownload,
} from "react-icons/md"
import { IoMdHelp } from "react-icons/io"
import Tooltip from "@material-ui/core/Tooltip"

const ActionButtons = ({
  randomizeFont,
  randomizeAnimation,
  randomizeBackground,
  toggleAnimation,
  toggleRedditMode,
  cycleFontSize,
  animationEnabled,
  redditMode,
  help,
  download,
  handleKeyPress,
}) => {
  return (
    <div id="action-buttons">
      <div className="grid grid-cols-3 md:grid-cols-4 gap-4">
        <Tooltip title="Randomize Font">
          <div
            id="btt-randomize-font"
            role="button"
            tabIndex={0}
            onClick={randomizeFont}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <FaFont />
          </div>
        </Tooltip>

        <Tooltip title="Cycle Font Size">
          <div
            id="btt-cycle-font-size"
            role="button"
            tabIndex={0}
            onClick={cycleFontSize}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <AiOutlineFontSize />
          </div>
        </Tooltip>

        <Tooltip title="Toggle Animation">
          <div
            id="btt-toggle-animation"
            role="button"
            tabIndex={0}
            onClick={toggleAnimation}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            {animationEnabled ? (
              <FiToggleRight className="opacity-100" />
            ) : (
              <FiToggleLeft className="opacity-25" />
            )}
          </div>
        </Tooltip>

        <Tooltip title="Random Animation">
          <div
            id="btt-random-animation"
            role="button"
            tabIndex={0}
            onClick={randomizeAnimation}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <MdSlowMotionVideo
              className={`${animationEnabled ? "opacity-100" : "opacity-25"}`}
            />
          </div>
        </Tooltip>

        <Tooltip title="Random Background">
          <div
            id="btt-random-background"
            role="button"
            tabIndex={0}
            onClick={randomizeBackground}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <MdPhotoLibrary />
          </div>
        </Tooltip>

        <Tooltip title="Toggle Reddit">
          <div
            id="btt-toggle-reddit"
            role="button"
            tabIndex={0}
            onClick={toggleRedditMode}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <FaRedditAlien
              className={`${redditMode ? "opacity-100" : "opacity-25"}`}
            />
          </div>
        </Tooltip>

        <Tooltip title="Download Quote">
          <div
            id="btt-download-quote"
            role="button"
            tabIndex={0}
            onClick={download}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <MdFileDownload />
          </div>
        </Tooltip>

        <Tooltip title="Help">
          <div
            id="btt-help"
            role="button"
            tabIndex={0}
            onClick={help}
            onKeyPress={handleKeyPress}
            className="relative z-20 w-10 h-10 cursor-pointer rounded-full bg-white text-gray-900 flex justify-center items-center hover:bg-gray-400"
          >
            <IoMdHelp />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ActionButtons
