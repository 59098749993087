import React from "react"

class Header extends React.Component {
  render() {
    return (
      <header className="relative bg-gradient text-white h-8 px-5 flex justify-between items-center">
        <div></div>
        <h1 className="font-semibold text-xs">
          Motivational Quotes - JustMotivate.Me
        </h1>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-xs underline"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfVOpyLJ3zNd38P7kxlC3fNWObH5LoDhBKQfKDaulvoZC-spw/viewform?usp=pp_url&entry.1264142562=Someone+Wise"
        >
          Submit Quote
        </a>
      </header>
    
    )    
  }
  componentDidMount () {
    const script = document.createElement("script");

    script.src = "https://w.appzi.io/bootstrap/bundle.js?token=dAGOq";
    script.async = true;
    script.classList.add("feedback-btn")

    document.body.appendChild(script);
  }
}

// const Header = () => {
//   return (
//     <header className="relative bg-gradient text-white h-8 px-5 flex justify-between items-center">
//       <div></div>
//       <h1 className="font-semibold text-xs">
//         Motivational Quotes - JustMotivate.Me
//       </h1>
//       <a
//         target="_blank"
//         rel="noopener noreferrer"
//         className="font-semibold text-xs underline"
//         href="https://docs.google.com/forms/d/e/1FAIpQLSfVOpyLJ3zNd38P7kxlC3fNWObH5LoDhBKQfKDaulvoZC-spw/viewform?usp=pp_url&entry.1264142562=Someone+Wise"
//       >
//         Submit Quote
//       </a>
//     </header>
   
//   )
// }
// componentDidMount () {
//   const script = document.createElement("script");

//   script.src = "https://w.appzi.io/bootstrap/bundle.js?token=dAGOq";
//   script.async = true;

//   document.body.appendChild(script);
// }
export default Header
