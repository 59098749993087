import React from "react"
import Advertisement from "../components/advertisement"
import Description from "../components/description"
import Header from "../components/header"
import Layout from "../components/layout"
import Quote from "../components/quote"
import Newsletter from "../components/newsletter"
import { FaShareSquare } from "react-icons/fa"
import Tooltip from "@material-ui/core/Tooltip"

const IndexPage = () => (
  <Layout>
    <Header />
    <Advertisement />
    <Quote />

    <div id="more-info" className="text-left max-w-3xl mx-auto px-6 md:px-0">
      <Description />
      <Newsletter />
    </div>

    <Tooltip title="Share JustMotivate.Me">
      <div
        role="button"
        tabIndex={0}
        className="fixed w-12 h-12 rounded-full right-0 bottom-0 mb-4 mr-4 bg-gray-200 hover:bg-gray-400 cursor-pointer text-gray-900 opacity-75 hover:opacity-100 flex justify-center items-center lg:hidden z-0"
        onKeyDown={e => {}}
        onClick={() => {
          if (navigator.share) {
            navigator
              .share({
                title: "Motivational Quotes 🙌 - JustMotivate.Me",
                text:
                  "Start 🌅 or end 🌇 your days with some handpicked inspirational and motivational quotes 🏆. Get ready to take on anything.",
                url: "https://justmotivate.me/",
              })
              .then(() => console.log("Successful share"))
              .catch(error => console.log("Error sharing", error))
          }
        }}
      >
        <FaShareSquare />
      </div>
    </Tooltip>
  </Layout>
)

export default IndexPage
