import React from "react"
import FooterMarkdownTag from "./footer-markdown-tag"
const fetch = require("node-fetch")
global.fetch = fetch
class Newsletter extends React.Component {
  constructor(props){
    super(props)
      this.state = {name: '',email:'',message:''};
      this.handleChangeName = this.handleChangeName.bind(this);
      this.handleChangeEmail = this.handleChangeEmail.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleOnConfirm = this.handleOnConfirm.bind(this)
  }
  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeEmail(event){
    this.setState({email: event.target.value});
  }
  handleOnConfirm(){
    this.setState({message:'Thanks for subscribing!'})

  }
  componentDidMount () {
    const script = document.createElement("script");
    script.src = "https://emailoctopus.com/bundles/emailoctopuslist/js/1.6/form-recaptcha.js";
    script.async = true;
    document.body.appendChild(script);
  }
  async handleSubmit(event) {
      try {
        const formUrl = "https://emailoctopus.com/lists/1fd6886b-b478-11eb-a3d0-06b4694bee2a/members/embedded/1.3s/add"
        const formData = new FormData()
        formData.append("field_0",this.state.email)
        formData.append("field_1",this.state.name)
        formData.append("hpc4b27b6e-eb38-11e9-be00-06b4694bee2a","")
        formData.append("successRedirectUrl","")
        let recaptcha = document.querySelector('input[name="g-recaptcha-response"]')
        if(recaptcha) {
          recaptcha = recaptcha.getAttribute("value")
        }
        else {
          return
        }
        formData.append("g-recaptcha-response",recaptcha)
        await fetch(formUrl,{
          method: "POST",
          body: formData
        })
        this.handleOnConfirm()
      }
      catch(error){
        this.message = "Sorry, an unknown error has occurred. Please try again later."
      }
    event.preventDefault();
  }
  render(){
    return (
    <div>
      <form
        onSubmit={this.handleSubmit}
        className="emailoctopus-form text-center"
        method="POST"
        data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6"
      >
        <h5 className="text-xl font-semibold">
          Motivational Quotes in your inbox{" "}
          <strong className="underline">every week</strong>!
        </h5>

        <div className="my-8 grid grid-cols-2 gap-4">
          <input
            className="col-span-1 text-center bg-gray-800 rounded py-2"
            required
            type="text"
            name="FIRSTNAME"
            placeholder="Name"
            value={this.state.name} 
            onChange={this.handleChangeName}
          />

          <input
            className="col-span-1 text-center bg-gray-800 rounded py-2"
            required
            type="email"
            name="EMAIL"
            placeholder="Email"
            value={this.state.email} 
            onChange={this.handleChangeEmail}
          />

          <input
            type="checkbox"
            className="hidden"
            name="OPT_IN"
            checked
            readOnly
          />

          <button
            type="submit"
            className="col-span-2 py-2 rounded text-gray-400 font-bold border border-gray-800 bg-gradient hover:opacity-75"
          >
            Motivate Me!
          </button>
          <span className="col-span-2 text-green-400">{this.state.message}</span>
        </div>
      </form>
      <FooterMarkdownTag></FooterMarkdownTag>
    </div>
  )
  }
}
export default Newsletter
