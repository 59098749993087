import { useStaticQuery, graphql } from "gatsby";
import React from "react"

const FooterMarkdownTag = () => {
const data = useStaticQuery(graphql`
  {
    markdownRemark(frontmatter: { id: { eq: "footer" } }) {
      html
    }
  }
`)
  return (
      <div
        className="markdown pb-6"
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />
  );
}
export default FooterMarkdownTag
