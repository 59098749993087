import { saveAs } from "file-saver"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useCookies } from "react-cookie"
import { IoIosShuffle } from "react-icons/io"
import "../css/animation/flicker.css"
import "../css/animation/fly-in.css"
import "../css/animation/glitch.css"
import "../css/animation/glow.css"
import "../css/animation/glow2.css"
import "../css/animation/groovy.css"
import "../css/animation/layers.css"
import "../css/animation/neon.css"
import "../css/animation/radiate.css"
import "../css/animation/reveal.css"
import "../css/animation/shadow-jump.css"
import "../css/animation/shake.css"
import animations from "../data/animations.json"
import fontFamilyOptions from "../data/fonts.json"
import useWindowDimensions from "../hooks/useWindowDimensions"
import ActionButtons from "./action-buttons"
import CallToAction from "./call-to-action"
import Help from "./help"
import styles from "./quote.module.css"

const fontSizeOptions = [
  "1.5rem",
  "2rem",
  "2.5rem",
  "3rem",
  "3.5rem",
  "4rem",
  "5rem",
]
const desktopFontSizeOptions = ["3rem", "4rem", "5rem", "6rem", "7rem", "8rem"]
const defaultQuote = {
  text: "Quotes are unavailable right now, please try again later.",
  by: "A Wise Man",
}
const defaultPhoto = {
  url: `https://images.unsplash.com/photo-1466872732082-8966b5959296?ixid=eyJhcHBfaWQiOjEyMDd9`,
  description: "Kirkjufellsfoss, Iceland",
  username: "amruthpillai",
  name: "Amruth Pillai",
}

const Quote = () => {
  const canvasRef = useRef(null)
  const { width } = useWindowDimensions()
  const [fontSize, setFontSize] = useState(fontSizeOptions[0])
  const [fontFamily, setFontFamily] = useState(fontFamilyOptions[1])
  const [animationEnabled, setAnimationEnabled] = useState(true)
  const [animation, setAnimation] = useState(animations[0])
  const [cookies, setCookie] = useCookies(["refreshCount"])
  const [redditMode, setRedditMode] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const [quote, setQuote] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [exporting, setExporting] = useState(false)

  useEffect(() => {
    const refreshCount = isNaN(parseInt(cookies.refreshCount))
      ? 0
      : parseInt(cookies.refreshCount)
    if (refreshCount <= 1) setAnimationEnabled(false)
    setCookie("refreshCount", refreshCount + 1)
    randomizeFont()
    randomizeAnimation()
    randomizeBackground()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const randomizeQuote = useCallback(() => {
    if (redditMode) {
      const random = Math.floor(Math.random() * (3 - 1) + 1)
      switch (random) {
        case 1:
          return getRedditQuote()
        case 2:
          return getShowerThought()
        default:
          return getRedditQuote()
      }
    } else {
      return getQuote()
    }
  }, [redditMode])

  useEffect(() => {
    randomizeQuote()
  }, [randomizeQuote])

  useEffect(() => {
    if (width > 1024) {
      setFontSize(desktopFontSizeOptions[0])
    } else {
      setFontSize(fontSizeOptions[0])
    }
  }, [width])

  const cycleFontSize = () => {
    if (width > 1024) {
      const currentIndex = desktopFontSizeOptions.indexOf(fontSize)
      const nextIndex = (currentIndex + 1) % desktopFontSizeOptions.length
      setFontSize(desktopFontSizeOptions[nextIndex])
    } else {
      const currentIndex = fontSizeOptions.indexOf(fontSize)
      const nextIndex = (currentIndex + 1) % fontSizeOptions.length
      setFontSize(fontSizeOptions[nextIndex])
    }
  }

  const toggleAnimation = () => setAnimationEnabled(!animationEnabled)

  const toggleRedditMode = () => setRedditMode(!redditMode)

  const openHelpDialog = () => setHelpOpen(true)

  const randomizeAnimation = () =>
    setAnimation(animations[Math.floor(Math.random() * animations.length)])

  const getQuote = () =>
    fetch(`api/getQuote`)
      .then(response => response.json())
      .then(setQuote)
      .catch(() => setQuote(defaultQuote))

  const getRedditQuote = () =>
    fetch(`api/getRedditQuote`)
      .then(response => response.json())
      .then(setQuote)
      .catch(() => setQuote(defaultQuote))

  const getShowerThought = () =>
    fetch(`api/getShowerThought`)
      .then(response => response.json())
      .then(setQuote)
      .catch(() => setQuote(defaultQuote))

  const randomizeBackground = () =>
    fetch(`api/getPhoto`)
      .then(response => response.json())
      .then(setPhoto)
      .catch(() => setPhoto(defaultPhoto))

  const randomizeFont = () =>
    setFontFamily(
      fontFamilyOptions[Math.floor(Math.random() * fontFamilyOptions.length)]
    )

  const download = () => {
    const hasWindow = typeof window !== "undefined"

    if (hasWindow) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      setExporting(true)
      setTimeout(async () => {
        const html2canvas = await import("html2canvas")
        const canvas = await html2canvas.default(canvasRef.current, {
          useCORS: true,
          allowTaint: true,
        })
        canvas.toBlob(blob => {
          saveAs(blob, `${Date.now()}.png`)
        })
        setExporting(false)
      }, 200)
    }
  }

  const handleKeyPress = e => {}

  return (
    <div>
      <div
        ref={canvasRef}
        className="relative z-10 w-full flex flex-col justify-center overflow-hidden items-center"
        style={
          exporting ? { height: "100vh" } : { height: "calc(100vh - 2rem)" }
        }
      >
        {photo && (
          <img
            crossOrigin="anonymous"
            className="absolute w-full h-full object-cover bg-gray-900"
            src={`${photo.url}&w=${width}&dpr=2`}
            alt={photo.description}
          />
        )}

        {quote && (
          <div className="lg:max-w-5xl w-4/5">
            <h1
              className={`relative text-white text-shadow-lg z-10 ${
                !animationEnabled || exporting ? "" : animation.className
              }`}
              style={{
                fontFamily,
                fontSize,
              }}
            >
              {quote.text}
            </h1>

            <a
              target="_blank"
              href={quote.url}
              rel="noopener noreferrer"
              className={`${quote.url ? "underline" : ""}`}
            >
              <h2 className="relative text-white font-semibold text-base lg:text-xl text-right mt-6 z-10">
                &mdash; {quote.by}
              </h2>
            </a>
          </div>
        )}

        {!exporting && (
          <div
            role="button"
            tabIndex={0}
            onClick={randomizeQuote}
            onKeyPress={handleKeyPress}
            className={styles.getNewQuoteButton}
          >
            <IoIosShuffle size="1.5rem" />
            <span className="text-sm ml-2">
              {redditMode ? "Get Reddit Wisdom" : "Get New Quote"}
            </span>
          </div>
        )}

        {!exporting && (
          <div id="quote-footer">
            <ActionButtons
              download={download}
              help={openHelpDialog}
              randomizeFont={randomizeFont}
              cycleFontSize={cycleFontSize}
              handleKeyPress={handleKeyPress}
              animationEnabled={animationEnabled}
              toggleAnimation={toggleAnimation}
              redditMode={redditMode}
              toggleRedditMode={toggleRedditMode}
              randomizeAnimation={randomizeAnimation}
              randomizeBackground={randomizeBackground}
            />

            <CallToAction />
          </div>
        )}
      </div>

      <Help open={helpOpen} handleClose={() => setHelpOpen(false)} />

      {quote && photo && (
        <p className="text-xs mt-2 opacity-50 text-white">
          Font:{" "}
          <a
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
            href={`https://fonts.google.com/specimen/${fontFamily}?utm_source=justmotivate.me&utm_medium=referral`}
          >
            {fontFamily}
          </a>{" "}
          | Animation:{" "}
          <a
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
            href={animation.url}
          >
            {animation.name}
          </a>{" "}
          | Photo by{" "}
          <a
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
            href={`https://unsplash.com/@${photo.username}?utm_source=justmotivate.me&utm_medium=referral`}
          >
            {photo.name}
          </a>{" "}
          on{" "}
          <a
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
            href="https://unsplash.com/?utm_source=justmotivate.me&utm_medium=referral"
          >
            Unsplash{" "}
          </a>
        </p>
      )}
    </div>
  )
}

export default Quote
