import { func } from "prop-types"
import { useState, useEffect } from "react"

export default function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined"

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (hasWindow) {
      function handleResize($event) {
        setWindowDimensions(getWindowDimensions())
        feedBackButtonStatus($event)
      }
      function feedBackButtonStatus() {
        const feedBackButton = document.getElementById("appzi-launch-button-7722f8dc-e044-48d9-a626-6f6247327d74")
        const element = document.getElementById("more-info")
        const rect = element.getBoundingClientRect();
        if(rect.y <400) {
          if(feedBackButton) {
            feedBackButton.classList.remove("hidden")
            feedBackButton.classList.add("visible")
          }
        }
        else {
          if(feedBackButton) {
            feedBackButton.classList.add("hidden")
            feedBackButton.classList.remove("visible")
          }
        }
      }
      function onLoad() {
        const feedBackButton = document.getElementById("appzi-launch-button-7722f8dc-e044-48d9-a626-6f6247327d74")
        feedBackButton.classList.add("hidden")
      }
      window.addEventListener("resize", handleResize)
      window.addEventListener("scroll",feedBackButtonStatus)
      window.addEventListener("load",onLoad)
      return () => window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow])

  return windowDimensions
}
