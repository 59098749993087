import React from "react"
import Dialog from "@material-ui/core/Dialog"

import { AiOutlineFontSize } from "react-icons/ai"
import { FiToggleRight } from "react-icons/fi"
import { FaFont, FaRedditAlien } from "react-icons/fa"
import {
  MdClose,
  MdPhotoLibrary,
  MdFileDownload,
  MdSlowMotionVideo,
} from "react-icons/md"

const legend = [
  {
    icon: FaFont,
    title: "Randomize Font",
    description: "Change the font style for the quote",
  },
  {
    icon: AiOutlineFontSize,
    title: "Cycle Font Size",
    description: "Increase or decrease the font size",
  },
  {
    icon: MdSlowMotionVideo,
    title: "Randomize Animation",
    description: "Change the font animation style",
  },
  {
    icon: MdPhotoLibrary,
    title: "Randomize Background",
    description: "Change the background image",
  },
  {
    icon: MdFileDownload,
    title: "Download Quote",
    description: "Download the Quote (without the animation)",
  },
  {
    icon: FiToggleRight,
    title: "Toggle Animation",
    description: "Show the quote without any animation",
  },
  {
    icon: FaRedditAlien,
    title: "Toggle Reddit",
    description:
      "Include random thoughts and quotes from Reddit. *Here be dragons* - when we pull random stuff from Reddit you can find something awesome, lame or downright creepy. ",
  },
]

const Help = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="p-6">
        <div className="pb-3 flex justify-between">
          <h5 class="font-bold">Help</h5>

          <div
            role="button"
            tabIndex="0"
            className="px-3"
            onClick={handleClose}
            onKeyDown={handleClose}
          >
            <MdClose size="1.25rem" />
          </div>
        </div>

        <hr className="pb-2" />

        <table className="table-auto">
          <thead>
            <tr>
              <th className="text-sm px-4 py-2 text-left">Icon</th>
              <th className="text-sm px-4 py-2 text-left">Description</th>
            </tr>
          </thead>
          <tbody>
            {legend.map(x => {
              const Icon = x.icon
              return (
                <tr key={x.title}>
                  <td className="border px-4 py-2">
                    <Icon size="1.25rem" />
                  </td>
                  <td className="border px-4 py-2">
                    <strong className="text-sm">{x.title}</strong>
                    <p>{x.description}</p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Dialog>
  )
}

export default Help
