import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Description = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { id: { eq: "description" } }) {
        html
      }
    }
  `)

  return (
    <div
      className="markdown"
      dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
    />
  )
}

export default Description
