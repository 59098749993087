import React from "react"
import Photo from "../images/saijo-400.jpg"
import Shape from "../images/shape.svg"

const CallToAction = () => {
  return (
    <div
      id="call-to-action"
      className="absolute flex justify-center items-center select-none"
    >
      <img src={Shape} alt="Blob Shape" />
      <div className="absolute flex flex-col items-end text-white">
        <img
          src={Photo}
          alt="Saijo George"
          className="w-12 h-12 bg-gray-400 rounded-full object-cover"
        />
        <span className="font-bold mt-2">by Saijo George</span>
        <a href="#more-info" className="underline text-sm">
          More Info
        </a>
      </div>
    </div>
  )
}

export default CallToAction
